import React from 'react';
import { CButton, CSpinner, CContainer, CRow, CCol } from '@coreui/react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import NumericInput from 'react-numeric-input';
import CreatableSelect from 'react-select/creatable';
import { useContext, useState, FormEvent } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import {Confirm} from 'react-st-modal'
import '../../assets/css/common.css'
import { FieldsContext } from '../../contexts/InputParameters/FieldsProvider';
import jwtInterceoptor from '../../views/shared/jwtInterceptor';
import useUserProfile from '../../hooks/useUserProfile'

interface Field {
    id: string;
    content: string;
    type: 'Text' | 'Number' | 'Dropdown';
    locked: boolean;
    options?: { value: string; label: string }[];
}

interface FormValues {
  input_weight?: number;
  strain_name?: string;
  pressure?: number;
  pressure2?: number;
  temperature1?: number;
  temperature2?: number;
  operator?: string;
}

interface FormErrors {
  input_weight?: string;
  strain_name?: string;
  pressure?: string;
  pressure2?: string;
  temperature1?: string;
  temperature2?: string;
  operator?: string;
}

function InputParameters() {
    const navigate = useNavigate();
    const { selectedFields } = useContext(FieldsContext);
    console.log(selectedFields)

    const initialValues: FormValues = {
        input_weight: 0,
        strain_name: '',
        pressure: 0,
        pressure2: 0,
        temperature1: 0,
        temperature2: 0,
        operator: localStorage.getItem('UserName') || '',
    };

    const [values, setValues] = useState<FormValues>(initialValues);
    const [errors, setErrors] = useState<FormErrors>({});
    const [loader, setLoader] = useState(false);
    const { userProfile, loading, error } = useUserProfile();
    const userType: any = userProfile?.user_type;

    // Handle the redirection
    const handleRedirect = () => {
        navigate('/InputParametersDnD');
    };

    const handleChange = (value: any, field: string) => {
        setValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: '',
        }));
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        
        // Validate form field values, if not validated, please return.
        const validationErrors = validateForm(values)
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
            return
        }
        
        try {
            setLoader(true)
            const response = await jwtInterceoptor.post(
                `${process.env.REACT_APP_API_URL}/user/api/input-parameters/`,
                values,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                },
            )
            setLoader(false)
            localStorage.setItem('input_parameter', response?.data?.id);
            localStorage.setItem('run', 'true')
            navigate('/ExtractoPredictionDashboard', {
                state: {
                    data: {
                        strain: values.strain_name,
                        input_weight: values.input_weight,
                        operator: values.operator,
                        pressure: values.pressure,
                        temperature: values.temperature1,
                        run: true
                    }
                }
            })
            
        } catch (err) {
            await Confirm(
                'Error occurred during processing request. Please contact administrator.',
                'Error',
            )
            setLoader(false)
            localStorage.setItem('run', 'true')
            navigate('/ExtractoPredictionDashboard', {
                state: {
                    data: {
                        input_weight: values.input_weight,
                        operator: values.operator,
                        strain: values.strain_name,
                        pressure: values.pressure,
                        temperature: values.temperature1,
                        run: true
                    }
                }
            })
        }
    }

    // Perform form validation for errors
    const validateForm = (formValues: FormValues) => {
        const errors: FormErrors = {}
    
        if (formValues.strain_name?.trim() === '') {
            errors.strain_name = 'Strain Name is required'
        }

        // Pressure validation
        if ('pressure' in formValues && (formValues.pressure === undefined || formValues.pressure <= 0)) {
            errors.pressure = 'Pressure must be greater than 0';
        }

        // Pressure2 validation
        if ('pressure2' in formValues && (formValues.pressure2 === undefined || formValues.pressure2 <= 0)) {
            errors.pressure2 = 'Pressure must be greater than 0';
        }
        
        // temperature must be between -20 and -100
        const temperature1 = parseFloat(String(formValues.temperature1));
        const temperature2 = parseFloat(String(formValues.temperature2));

        // Check if the value is a valid number
        if (temperature1 && isNaN(temperature1)) {
            errors.temperature1 = 'Value must be a valid number between -100 and -20';
        } else if (temperature1 < -100 || temperature1 > -20) {
            // Check if the number is within the valid range
            errors.temperature1 = 'Temperature must be between -100 and -20';
        }

        if (isNaN(temperature2)) {
            errors.temperature2 = 'Value must be a valid number between -100 and -20';
        } else if (temperature2 < -100 || temperature2 > -20) {
            // Check if the number is within the valid range
            errors.temperature2 = 'Temperature must be between -100 and -20';
        }

        if (formValues.operator === null) {
            errors.operator = 'Operator is required'
        }
        
        return errors
    }

    const renderField = (fieldId: string) => {

        console.log(fieldId)

        const field: Field = selectedFields.find((f: Field) => f.id === fieldId);
        if (!field) return null;

        switch (fieldId) {
            case '1': // Strain Name
                return (
                <CCol md="6 mt-1rem" key={fieldId}>
                    <label className="form-label" htmlFor="strain_name">Strain Name:</label>
                    <input
                    type="text"
                    className="form-control"
                    value={values.strain_name}
                    onFocus={() =>
                        setErrors((prevErrors) => ({
                        ...prevErrors,
                        strain_name: '',
                        }))
                    }
                    onChange={(e) => handleChange(e.target.value, 'strain_name')}
                    />
                    {errors.strain_name && <p className="error">{errors.strain_name}</p>}
                </CCol>
                );
            case '2': // Pressure
                return (
                <CCol md="6 mt-1rem" key={fieldId}>
                    <label className="form-label" htmlFor="pressure">Pressure (psi):</label>
                    <NumericInput
                    className="form-control"
                    min={0}
                    max={100}
                    value={values.pressure}
                    onFocus={() =>
                        setErrors((prevErrors) => ({
                        ...prevErrors,
                        pressure: '',
                        }))
                    }
                    onChange={(value) => handleChange(value, 'pressure')}
                    />
                    {errors.pressure && <p className="error">{errors.pressure}</p>}
                </CCol>
                );

            case '6': // Pressure 2
                return (
                <CCol md="6 mt-1rem" key={fieldId}>
                    <label className="form-label" htmlFor="pressur2">Pressure 2(psi):</label>
                    <NumericInput
                    className="form-control"
                    min={0}
                    max={100}
                    value={values.pressure2}
                    onFocus={() =>
                        setErrors((prevErrors) => ({
                        ...prevErrors,
                        pressure2: '',
                        }))
                    }
                    onChange={(value) => handleChange(value, 'pressure2')}
                    />
                    {errors.pressure2 && <p className="error">{errors.pressure2}</p>}
                </CCol>
            );
            case '3': // Temperature 1
                return (
                <CCol md="6 mt-1rem" key={fieldId}>
                    <label className="form-label" htmlFor="temperature1">Temperature 1 (°C):</label>
                    <input
                    type="text"
                    className="form-control"
                    value={values.temperature1}
                    onFocus={() =>
                        setErrors((prevErrors) => ({
                        ...prevErrors,
                        temperature1: '',
                        }))
                    }
                    onChange={(e) => handleChange(e.target.value, 'temperature1')}
                    />
                    {errors.temperature1 && <p className="error">{errors.temperature1}</p>}
                </CCol>
                );
            case '7': // Temperature 2
                return (
                <CCol md="6 mt-1rem" key={fieldId}>
                    <label className="form-label" htmlFor="temperature2">Temperature 2 (°C):</label>
                    <input
                    type="text"
                    className="form-control"
                    value={values.temperature2}
                    onFocus={() =>
                        setErrors((prevErrors) => ({
                        ...prevErrors,
                        temperature2: '',
                        }))
                    }
                    onChange={(e) => handleChange(e.target.value, 'temperature2')}
                    />
                    {errors.temperature2 && <p className="error">{errors.temperature2}</p>}
                </CCol>
                );
            case '7': // Pressure 2
                return (
                <CCol md="6 mt-1rem" key={fieldId}>
                    <label className="form-label" htmlFor="pressure2">Pressure 2 (psi):</label>
                    <NumericInput
                    className="form-control"
                    min={0}
                    max={100}
                    value={values.pressure2}
                    onFocus={() =>
                        setErrors((prevErrors) => ({
                        ...prevErrors,
                        pressure2: '',
                        }))
                    }
                    onChange={(value) => handleChange(value, 'pressure2')}
                    />
                    {errors.pressure2 && <p className="error">{errors.pressure2}</p>}
                </CCol>
                );
            case '4': // Input Weight
                return (
                <CCol md="6 mt-1rem" key={fieldId}>
                    <label className="form-label" htmlFor="input_weight">Input Weight (Lbs):</label>
                    <NumericInput
                    className="form-control"
                    min={0}
                    max={99}
                    value={values.input_weight}
                    onFocus={() =>
                        setErrors((prevErrors) => ({
                        ...prevErrors,
                        input_weight: '',
                        }))
                    }
                    onChange={(value) => handleChange(value, 'input_weight')}
                    />
                    {errors.input_weight && <p className="error">{errors.input_weight}</p>}
                </CCol>
                );
            case '5': // Operator
                return (
                <CCol md="6 mt-1rem" key={fieldId}>
                    <label className="form-label" htmlFor="operator">Operator:</label>
                    <CreatableSelect
                    className="classic"
                    isSearchable
                    options={[
                        { label: 'Operator 1', value: 'Operator 1' },
                        { label: 'Operator 2', value: 'Operator 2' },
                    ]}
                    onFocus={() =>
                        setErrors((prevErrors) => ({
                        ...prevErrors,
                        operator: '',
                        }))
                    }
                    onChange={(selectedOption) => handleChange(selectedOption?.value, 'operator')}
                    />
                    {errors.operator && <p className="error">{errors.operator}</p>}
                </CCol>
                );
            default:
                return renderCustomField(field);
        }
    };

    // Function to render custom fields
    const renderCustomField = (field: Field) => {
        if (!field) return null;

        switch (field.type) {
            case 'Text':
                return (
                    <CCol md="6 mt-1rem" key={field.id}>
                        <label className="form-label" htmlFor={field.id}>{field.content}:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={values[field.id as keyof FormValues] || ''}
                            onFocus={() => setErrors((prevErrors) => ({ ...prevErrors, [field.id]: '' }))}
                            onChange={(e) => handleChange(e.target.value, field.id as keyof FormValues)}
                        />
                        {errors[field.id as keyof FormErrors] && <p className="error">{errors[field.id as keyof FormErrors]}</p>}
                    </CCol>
                );
            case 'Number':
                return (
                    <CCol md="6 mt-1rem" key={field.id}>
                        <label className="form-label" htmlFor={field.id}>{field.content}:</label>
                        <NumericInput
                            className="form-control"
                            min={0}
                            max={100}
                            value={values[field.id as keyof FormValues] || 0}
                            onFocus={() => setErrors((prevErrors) => ({ ...prevErrors, [field.id]: '' }))}
                            onChange={(value) => handleChange(value || 0, field.id as keyof FormValues)}
                        />
                        {errors[field.id as keyof FormErrors] && <p className="error">{errors[field.id as keyof FormErrors]}</p>}
                    </CCol>
                );
            case 'Dropdown':
                return (
                    <CCol md="6 mt-1rem" key={field.id}>
                        <label className="form-label" htmlFor={field.id}>{field.content}:</label>
                        <CreatableSelect
                            className="classic"
                            isSearchable
                            options={field.options || []}
                            onFocus={() => setErrors((prevErrors) => ({ ...prevErrors, [field.id]: '' }))}
                            onChange={(selectedOption) => handleChange(selectedOption?.value || '', field.id as keyof FormValues)}
                        />
                        {errors[field.id as keyof FormErrors] && <p className="error">{errors[field.id as keyof FormErrors]}</p>}
                    </CCol>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}>
            {/* Left Column */}
            <div style={{flex: 1, display: 'flex', justifyContent: 'flex-start'}}>
                <CButton
                    color=""
                    onClick={() => {
                        navigate('/ExtractoPredictionDashboard');
                    }}
                    style={{
                        fontFamily: 'Inter',
                        fontWeight: '600',
                        fontSize: '14px',
                        color: '#8292fa',
                        backgroundColor: 'transparent',
                        border: 'none',
                    }}
                >
                    <IoIosArrowBack style={{marginRight: '5px'}}/>
                    Back to Extracto Prediction Dashboard
                </CButton>
            </div>
            <div style={{ flex: 2 }}>
                {loader ? (
                <CSpinner color="success" className="spinnerStyle" />
                ) : (
                    <>
                        <h2 className="text-line input-text mt-2rem">Please provide following values</h2>
                        <form onSubmit={handleSubmit}>
                            <CContainer>
                            <CRow>
                                {selectedFields.map((field: any) => renderField(field.id))}
                            </CRow>
                            </CContainer>
                            <br/>
                            <div className="centered-container">
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{
                                        backgroundColor: '#19d22c',
                                        color: '#000000',
                                        fontSize: '14px',
                                        width: '250px',
                                        height: '45px',
                                        marginBottom: '15px',
                                        fontWeight: '600',
                                    }}
                                >
                                    Initiate Run
                                </Button>
                            </div>
                        </form>
                    </>
                )}
            </div>
            {/* Right Column */}
			<div style={{flex: 1}}>
                {userType === 'owner' && (
                    <p onClick={handleRedirect} className="cursor-pointer text-red text-center hover:underline">
                        Want to customize the fields? Click here
                    </p>
                )}
            </div>
        </div>
    );
}

export default InputParameters;
